
























































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import TimetableVerifyEvents from '@/components/timetables/TimetableVerifyEvents.vue';
import { ConflictEventAction } from '@/constants';
import customerService from '@/services/customer.service';
import employeeService from '@/services/employee.service';
import Vue, { PropType } from 'vue';
import { Event } from '@/model/event';
import Alert from '@/components/shared/Alert.vue';
import { useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  components: {
    LoadingIndicator,
    TimetableVerifyEvents,
    Alert
  },
  props: {
    actions: {
      type: Array as PropType<ConflictEventAction[]>,
      default: () => {
        return [ConflictEventAction.CANCEL, ConflictEventAction.CHECK_DETAILS];
      }
    },
    buttonText: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    idSuffix: {
      type: String,
      default: () => ''
    },
    save: {
      type: Function,
      required: true
    },
    setValidationErrors: {
      type: Function,
      default: () => null
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    verify: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      eventsNotAllowingProcessing: [] as Event[],
      isFetching: false,
      sendingData: false,
      errors: false,
      errorCode: null as number | null,
      errorCodes: {
        validation: 422,
        conflict: 409
      }
    };
  },
  computed: {
    ...mapStores(useUiStore)
  },
  methods: {
    async onOpen() {
      try {
        this.isFetching = true;
        this.errors = false;
        this.errorCode = null;
        this.eventsNotAllowingProcessing = [];
        await this.verify();
        this.isFetching = false;
      } catch (exception) {
        console.error(exception);
        this.isFetching = false;
        this.errors = true;
        if (exception.errors !== undefined) {
          this.errorCode = exception.code;
          if (
            exception.code === this.errorCodes.validation &&
            this.setValidationErrors
          ) {
            this.setValidationErrors(exception.errors, exception.message);
          } else if (exception.code === this.errorCodes.conflict) {
            this.eventsNotAllowingProcessing = exception.errors;
          }
        }
      }
    },
    async onOk(bvModalEvt) {
      if (this.errors && this.errorCode === this.errorCodes.validation) {
        return;
      }
      if (bvModalEvt) bvModalEvt.preventDefault();
      if (this.errors && this.errorCode === this.errorCodes.conflict) {
        try {
          this.sendingData = true;
          await Promise.all(
            this.eventsNotAllowingProcessing.map(event => {
              if (event.booking_id) {
                return customerService.cancelBooking(event.booking_id);
              } else {
                return employeeService.deleteEvent(event.id);
              }
            })
          );
        } catch (exception) {
          console.error(exception);
          this.uiStore.alert(exception.message);
          this.sendingData = false;
          return;
        }
      }
      try {
        this.sendingData = true;
        const saved: boolean = await this.save();
        this.$bvModal.hide('check-events-conflicts-modal' + this.idSuffix);
        if (saved) {
          this.$emit('saved');
        }
      } catch (exception) {
        console.error(exception);
        this.uiStore.alert(exception.message);
      }
      this.sendingData = false;
    }
  }
});
