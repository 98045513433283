import { defineStore } from 'pinia';
import { getInitialEntityState } from '@/store/common/entities-state';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { createEntityGetters } from '@/store/common/entities-getters';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import employeeService from '@/services/employee.service';
import * as Sentry from '@sentry/vue';
import { useUiStore } from '@/store/ui-store';

const initialEntityState = {
  ...getInitialEntityState<TimetableDateException>()
};
/**
 * Contient les timetable exceptions de l'employé sélectionné (défini dans employeesStore.currentEntity.id)
 */
export const useTimetableExceptionsStore = defineStore('timetableExceptions', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<TimetableDateException>()
  },
  actions: {
    ...createEntityActions<TimetableDateException>(
      Collection.TIMETABLE_DATE_EXCEPTIONS,
      initialEntityState
    ),
    async findAll(
      employeeId: number,
      force: boolean = true,
      loader: boolean = true
    ) {
      const uiStore = useUiStore();
      try {
        if (this.entities.length === 0 || force) {
          if (loader) {
            this.fetching = true;
          }
          const response = await employeeService.getTimetableDateExceptions(
            employeeId
          );
          this.entities = response.exceptions;
          this.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        this.failure = true;
        this.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    },
    async findAllPaginated(
      employeeId: number,
      past: boolean,
      payload?: {
        limit?: number;
        page?: number;
        force?: boolean;
      }
    ) {
      const uiStore = useUiStore();
      try {
        if (this.entities.length === 0 || payload?.force) {
          this.fetching = true;
          const response = await employeeService.getTimetableDateExceptions(
            employeeId,
            payload?.page,
            past,
            payload?.limit
          );
          this.entities = response.exceptions;
          this.pagination = response.pagination;
          this.fetching = false;
        }
      } catch (exception) {
        console.error(exception);
        this.failure = true;
        this.fetching = false;
        uiStore.alert(exception.message);
        Sentry.captureException(exception);
      }
    }
  }
});
