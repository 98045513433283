



























































































import employeeService from '@/services/employee.service';
import moment from 'moment';
import customerService from '@/services/customer.service';
import { TIME_FORMAT } from '@/filters/time';
import { API_DATE_FORMAT, ConflictEventAction } from '@/constants';
import Vue, { PropType } from 'vue';
import { Event } from '@/model/event';
import { Employee } from '@/model/employee';
import { Customer } from '@/model/customer';
import { useEmployeesStore } from '@/store/employees-store';
import { mapStores } from 'pinia';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { useUiStore } from '@/store/ui-store';
import i18n from '@/config/i18n';

interface Conflict {
  type: 'booking' | 'event';
  entityId: number; // ID du Booking ou de l'Event
  summary: string | null;
  start: string;
  end: string;
  customer?: Customer;
  services?: string[];
}

interface ConflictsWithDate {
  date: string;
  conflicts: Conflict[];
}

export default Vue.extend({
  props: {
    eventsNotAllowingProcessing: {
      type: Array as PropType<Event[]>,
      required: true
    },
    actions: {
      type: Array as PropType<ConflictEventAction[]>,
      required: true
    }
  },
  data() {
    return {
      sendingData: false,
      CONFLICT_EVENT_ACTION: ConflictEventAction,
      conflictEventActionHumanized: {
        [ConflictEventAction.CANCEL]: i18n.t('conflictEventAction.cancelEvent'),
        [ConflictEventAction.CREATE_TIMETABLE_DATE_EXCEPTION]: i18n.t(
          'conflictEventAction.createTimetableException'
        ),
        [ConflictEventAction.CHECK_DETAILS]: i18n.t(
          'conflictEventAction.checkDetails'
        )
      }
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useTimetableExceptionsStore, useUiStore),
    conflictsWithDates(): ConflictsWithDate[] {
      const conflictsWithDates: ConflictsWithDate[] = [];
      this.conflicts.forEach(conflict => {
        const date = moment(conflict.start).format(API_DATE_FORMAT);
        const index: number = conflictsWithDates.findIndex(conflictsByDate => {
          return conflictsByDate.date === date;
        });
        if (index >= 0) {
          conflictsWithDates[index].conflicts.push(conflict);
        } else {
          conflictsWithDates.push({
            date,
            conflicts: [conflict]
          });
        }
      });

      return conflictsWithDates;
    },
    conflicts(): Conflict[] {
      const conflicts: Conflict[] = [];
      this.eventsNotAllowingProcessing.forEach(event => {
        if (event.booking_id) {
          const conflict: Conflict = {
            entityId: event.booking_id,
            summary: event.summary,
            customer: event.booking.customer,
            start: event.start,
            end: event.end,
            services: [event.service.name],
            type: 'booking'
          };
          // Booking avec plusieurs services (Event)
          const conflictIndex = conflicts.findIndex(
            existingConflict => existingConflict.entityId === event.booking_id
          );
          if (conflictIndex >= 0) {
            let serviceIndex = -1;
            if (event.service_part_id) {
              serviceIndex = conflicts[conflictIndex].services.findIndex(
                existingService => existingService === event.service.name
              );
            }
            if (serviceIndex === -1) {
              conflicts[conflictIndex].services.push(event.service.name);
            }
          } else {
            // Booking avec 1 seul service
            conflicts.push(conflict);
          }
        } else {
          // Les autres events sont gardés tel quel.
          conflicts.push({
            type: 'event',
            entityId: event.id,
            summary: event.summary,
            start: event.start,
            end: event.end
          });
        }
      });

      return conflicts;
    },
    employee(): Employee {
      return this.employeesStore.currentEntity;
    }
  },
  methods: {
    getTimetableExceptions() {
      return this.timetableExceptionsStore.findAll(this.employee.id);
    },
    async handleEvent(action: ConflictEventAction, conflict: Conflict) {
      switch (action) {
        case ConflictEventAction.CANCEL:
          await this.cancelEvent(conflict);
          break;
        case ConflictEventAction.CREATE_TIMETABLE_DATE_EXCEPTION:
          await this.createDateException(conflict);
          break;
        case ConflictEventAction.CHECK_DETAILS:
          await this.$router.push({
            name: 'CustomersView',
            params: { id: conflict.customer.id.toString() }
          });
          break;
      }
    },
    async cancelEvent(conflict: Conflict): Promise<void> {
      this.sendingData = true;
      try {
        if (conflict.type === 'booking') {
          await customerService.cancelBooking(conflict.entityId);
        } else {
          await employeeService.deleteEvent(conflict.entityId);
        }
        this.$emit('updated');
        this.sendingData = false;
      } catch (exception) {
        this.sendingData = false;
        this.uiStore.alert(exception.message);
      }
    },
    async createDateException(conflict: Conflict): Promise<void> {
      this.sendingData = true;
      try {
        const dateToKeep = moment(conflict.start).format(API_DATE_FORMAT);
        const timetableForDate = (
          await employeeService.getSchedulesBetweenDates(
            dateToKeep,
            dateToKeep,
            this.employee.id
          )
        )[0];
        if (timetableForDate.isException !== true) {
          const timeRanges = timetableForDate.timeRanges.map(timeRange => {
            return {
              start: moment(timeRange.start).format(TIME_FORMAT),
              end: moment(timeRange.end).format(TIME_FORMAT),
              location_id: timeRange.location.id
            };
          });
          await employeeService.addTimetableDateException({
            date: dateToKeep,
            available: true,
            employee_id: this.employee.id,
            time_range_exceptions: timeRanges
          });
          await this.getTimetableExceptions();
        }
        this.$emit('updated');
        this.sendingData = false;
      } catch (exception) {
        this.sendingData = false;
        this.uiStore.alert(exception.message);
      }
    }
  }
});
